<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  ></v-skeleton-loader>
  <div v-else>
    <v-card :key="data.id">
      <v-card-title>
        Descrição do vale {{ verifyLastStatus }}
        {{
          dataVale.value_paid !== null
            ? Number(dataVale.value_paid).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : ''
        }}
      </v-card-title>
      <v-card-text>
        <v-timeline
          dense
          class="timeline-custom-dense timeline-custom-dots"
        >
          <v-timeline-item
            v-if="dataVale.user"
            small
            class="mb-6"
            color="info"
          >
            <div class="container">
              <div class="container-image">
                <v-avatar>
                  <v-img
                    v-if="!imageProfileUser"
                    src="@/assets/images/avatars/avatar-none.png"
                  ></v-img>
                  <v-img
                    v-else
                    :src="imageProfileUser"
                  ></v-img>
                </v-avatar>
              </div>
              <div class="container-content">
                <div>
                  <h3>
                    {{ dataVale.user.name }} -
                    {{ Number(dataVale.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                  </h3>
                </div>
                <div>
                  <span>{{ dateFormat(dataVale.date_user) }}</span>
                </div>
                <br />
              </div>
            </div>
            <div class="container-description">
              <span>
                {{ dataVale.description ? removeHtmlInString(dataVale.description) : '' }}
              </span>
            </div>
          </v-timeline-item>
          <v-timeline-item
            v-if="dataVale.user_leader"
            small
            class="mb-6 timeline-container"
            :color="
              dataVale.status_leader === 'APROVADO'
                ? 'success'
                : dataVale.status_leader === 'REPROVADO'
                  ? 'error'
                  : 'info'
            "
          >
            <div class="container">
              <div class="container-image">
                <v-avatar>
                  <v-img
                    v-if="!imageProfileLeader"
                    src="@/assets/images/avatars/avatar-none.png"
                  ></v-img>
                  <v-img
                    v-else
                    :src="imageProfileLeader"
                  ></v-img>
                </v-avatar>
              </div>
              <div class="container-content">
                <div>
                  <h3>{{ dataVale.user_leader.name }}</h3>
                </div>
                <div>
                  <span>{{ verifyDateLeader }}</span>
                </div>
                <br />
              </div>
            </div>
            <div class="container-description">
              {{ dataVale.description_leader ? removeHtmlInString(dataVale.description_leader) : '' }}
            </div>
          </v-timeline-item>
          <v-timeline-item
            v-if="dataVale.user_director"
            small
            class="mb-6"
            :color="
              dataVale.status_director === 'APROVADO'
                ? 'success'
                : dataVale.status_director === 'REPROVADO'
                  ? 'error'
                  : 'info'
            "
          >
            <div class="container">
              <div class="container-image">
                <v-avatar>
                  <v-img
                    v-if="!imageProfileDirector"
                    src="@/assets/images/avatars/avatar-none.png"
                  ></v-img>
                  <v-img
                    v-else
                    :src="imageProfileDirector"
                  ></v-img>
                </v-avatar>
              </div>
              <div class="container-content">
                <div>
                  <h3>{{ dataVale.user_director.name }}</h3>
                </div>
                <div>
                  <span>{{ verifyDateDirector }}</span>
                </div>
                <br />
              </div>
            </div>
            <div class="container-description">
              {{ dataVale.description_director ? removeHtmlInString(dataVale.description_director) : '' }}
            </div>
          </v-timeline-item>

          <v-timeline-item
            v-if="dataVale.user_financial"
            small
            class="mb-6"
            :color="dataVale.status_financial ? 'success' : 'info'"
          >
            <div class="container">
              <div class="container-image">
                <v-avatar>
                  <v-img
                    v-if="!imageProfileFinancial"
                    src="@/assets/images/avatars/avatar-none.png"
                  ></v-img>
                  <v-img
                    v-else
                    :src="imageProfileFinancial"
                  ></v-img>
                </v-avatar>
              </div>
              <div class="container-content">
                <div>
                  <h3>{{ dataVale.user_financial.name }}</h3>
                </div>
                <div>
                  <span>{{ verifyDateFinancial }}</span>
                </div>
                <br />
              </div>
            </div>
            <div class="container-description">
              {{ dataVale.description_financial ? removeHtmlInString(dataVale.description_financial) : '' }}
            </div>
          </v-timeline-item>
        </v-timeline>

        <vue-editor
          v-show="verifyShowTextArea"
          v-model="inputDescription"
          data-test="description-input"
        ></vue-editor>

        <vue-editor
          v-show="userIsFinancial && dataVale.status_director && !dataVale.status_financial"
          v-model="inputDescriptionFinancial"
          data-test="description-input-financial"
        ></vue-editor>

        <div class="d-flex justify-end mt-5">
          <v-btn
            v-show="userIsFinancial && dataVale.status_director && !dataVale.status_financial"
            color="success"
            class="ml-5"
            data-test="payment-button"
            @click="sendPaymentFinancial()"
          >
            <span v-if="!loadingCircleChat">Pagar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>

          <v-btn
            v-show="verifyShowTextArea"
            color="success"
            class="ml-5"
            data-test="aprove-button"
            @click="aproveOrReprove('APROVADO')"
          >
            <span v-if="!loadingCircleChat">Aprovar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>

          <v-btn
            v-show="verifyShowTextArea"
            color="warning"
            class="ml-5"
            data-test="reprove-button"
            @click="aproveOrReprove('REPROVADO')"
          >
            Reprovar
          </v-btn>

          <v-btn
            color="error"
            class="ml-5"
            data-test="close-button"
            @click="closeModal"
          >
            Fechar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import LocalStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
  },

  data() {
    return {
      skeleton: false,
      loadingCircleChat: false,
      inputDescription: '',
      solicitationResponse: '',
      hierarchicalProfile: '',
      userProfile: [],
      userIsFinancial: '',
      inputValueFinancial: '',
      inputDescriptionFinancial: '',
      dataVale: {},
      imageProfileUser: '',
      imageProfileLeader: '',
      imageProfileDirector: '',
      imageProfileFinancial: '',
    }
  },

  computed: {
    verifyShowTextArea() {
      if (this.hierarchicalProfile === 'nivel_2' && this.dataVale.date_leader === null) {
        return true
      }

      if (this.hierarchicalProfile === 'nivel_3' && this.dataVale.date_director === null) {
        return true
      }

      return false
    },

    verifyLastStatus() {
      if (this.dataVale.date_leader !== null && this.dataVale.date_director === null) {
        return `- ${this.dataVale.status_leader} PELO LÍDER`
      }

      if (this.dataVale.date_director !== null) {
        if (this.dataVale.date_financial !== null) {
          return `- ${this.dataVale.status_financial}`
        }

        return `- ${this.dataVale.status_director} PELO DIRETOR`
      }

      return '- Solicitado'
    },

    verifyProfilePhoto() {
      if (this.imageProfileUser) {
        return this.imageProfileUser
      }

      if (this.imageProfileLeader) {
        return this.imageProfileLeader
      }

      if (this.imageProfileDirector) {
        return this.imageProfileDirector
      }

      if (this.imageProfileFinancial) {
        return this.imageProfileFinancial
      }

      return ''
    },

    verifyDateLeader() {
      if (this.dataVale.date_leader !== null) {
        return this.dateFormat(this.dataVale.date_leader)
      }

      return ''
    },

    verifyDateDirector() {
      if (this.dataVale.date_director !== null) {
        return this.dateFormat(this.dataVale.date_director)
      }

      return ''
    },

    verifyDateFinancial() {
      if (this.dataVale.date_financial !== null) {
        return this.dateFormat(this.dataVale.date_financial)
      }

      return ''
    },
  },

  created() {
    this.userProfile = LocalStorageSlim.get('userProfile', { decrypt: true })
    this.hierarchicalProfile = this.userProfile.hierarchical_profile
    this.userIsFinancial = this.userProfile.profile.profile === 'Financeiro'

    this.getDataById()
  },

  methods: {
    async getProfilePhotoUser() {
      const idUser = this.dataVale.user.id

      await axiosIns
        .get(`/api/v1/records/user/display_photo/${idUser}`)
        .then(res => {
          this.imageProfileUser = res.data
        })
        .catch()
    },

    async getProfilePhotoLeader() {
      const idUserLeader = this.dataVale.user_leader.id

      await axiosIns
        .get(`/api/v1/records/user/display_photo/${idUserLeader}`)
        .then(res => {
          this.imageProfileLeader = res.data
        })
        .catch()
    },

    async getProfilePhotoDirector() {
      if (!this.dataVale.user_director) {
        return
      }

      const idUserDirector = this.dataVale.user_director.id

      await axiosIns
        .get(`/api/v1/records/user/display_photo/${idUserDirector}`)
        .then(res => {
          this.imageProfileDirector = res.data
        })
        .catch()
    },

    async getProfilePhotoFinancial() {
      const idUserFinancial = this.dataVale.user_financial.id

      await axiosIns
        .get(`/api/v1/records/user/display_photo/${idUserFinancial}`)
        .then(res => {
          this.imageProfileFinancial = res.data
        })
        .catch()
    },

    async getDataById() {
      this.skeleton = true
      await axiosIns
        .get(`/api/v1/integration/vale/edit/${this.data.id}`)
        .then(resp => {
          this.dataVale = resp.data.data
          this.skeleton = false
        })
        .catch()

      this.getProfilePhotoUser()
      this.getProfilePhotoLeader()
      this.getProfilePhotoDirector()
    },

    aproveOrReprove(solicitationResponse) {
      this.solicitationResponse = solicitationResponse
      if (this.hierarchicalProfile === 'nivel_2') {
        this.sendAproveOrReproveLeader()
      } else if (this.hierarchicalProfile === 'nivel_3') {
        this.sendAproveOrReproveDirector()
      }
    },

    async sendAproveOrReproveLeader() {
      if (this.solicitationResponse === 'APROVADO') {
        this.loadingCircleChat = true
      }
      const body = {
        status_leader: this.solicitationResponse,
        description_leader: this.inputDescription,
      }

      await axiosIns
        .put(`/api/v1/integration/vale/update/${this.data.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
            showConfirmButton: false,
            timer: 3000,
          })
          this.loadingCircleChat = false
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })

      this.updatedTable()
      this.closeModal()
    },

    async sendAproveOrReproveDirector() {
      if (this.solicitationResponse === 'APROVADO') {
        this.loadingCircleChat = true
      }
      const body = {
        status_director: this.solicitationResponse,
        description_director: this.inputDescription,
      }

      await axiosIns
        .put(`/api/v1/integration/vale/update/${this.data.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
            showConfirmButton: false,
            timer: 3000,
          })
          this.loadingCircleChat = false
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })

      this.updatedTable()
      this.closeModal()
    },

    async sendPaymentFinancial() {
      this.loadingCircleChat = true
      const body = {
        description_financial: this.inputDescriptionFinancial,
        status_financial: 'PAGO',
        value_paid: Number(this.data.value),
      }

      await axiosIns
        .put(`/api/v1/integration/vale/update/${this.data.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
            showConfirmButton: false,
            timer: 3000,
          })

          this.loadingCircleChat = false
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })

      this.updatedTable()
      this.closeModal()
    },
  },
}

// TODO - Trabalhar na responsividade do container description
</script>
<style scoped>
.timeline-container {
  width: 100%;
}
.container {
  display: flex;
  flex-direction: row;
}
.container-image {
  margin-right: 20px;
}
.container-description {
  max-width: 30.5556rem;
  margin-right: 1.1111rem;
}

/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
