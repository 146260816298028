<template>
  <v-card>
    <v-card-title class="justify-center mb-2"> Solicitação de vales </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6" class="pb-0">
          <v-text-field
            v-model="inputValue"
            :rules="[rules.required]"
            data-test="value-input"
            label="Valor"
            type="number"
            prefix="$"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="userProfile.name"
            :rules="[rules.required]"
            label="Líder"
            outlined
            readonly
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="6" class="pb-0">
          <v-autocomplete
            v-model="sectorIdSelected"
            :items="sectorsList"
            :rules="[rules.required]"
            data-test="sector-select"
            item-text="name"
            item-value="id"
            label="Setor"
            clearable
            outlined
            dense
          >
          </v-autocomplete>

          <v-autocomplete
            v-model="voucherTypeSelected"
            :items="voucherTypesList"
            :rules="[rules.required]"
            data-test="voucher-type-select"
            item-text="description"
            item-value="id"
            label="Tipo de vale"
            clearable
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-autocomplete
        v-show="showInstallmentsField"
        v-model="installmentsNumberSelected"
        :items="numberParcels"
        label="Número de parcelas"
        class="mt-4"
        data-test=""
        clearable
        outlined
        dense
      >
      </v-autocomplete>
      <small v-show="showMessageMinimumParcelValue" class="small-text">
        {{ minimumInstallmentValue }}
      </small>

      <vue-editor v-model="inputDescription" data-test="description-input" class="mt-4"></vue-editor>

      <div class="d-flex justify-end mt-5">
        <v-btn color="error" data-test="close-button" @click="closeModal"> Cancelar </v-btn>

        <v-btn color="info" class="ml-5" data-test="send-button" @click="sendModalData">
          <span v-if="!loadingCircle">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiCalendar } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters],

  props: {
    sectorsList: {
      type: [Array, Object],
      required: true,
    },

    voucherTypesList: {
      type: [Array, Object],
      required: true,
    },

    employeesList: {
      type: [Array, Object, String],
      required: true,
    },

    listOfInstallments: {
      type: [Array, Object, String],
      required: true,
    },
  },

  data() {
    return {
      inputValue: '',
      inputDescription: '',
      employeeSelected: '',
      leaderIdSelected: '',
      sectorIdSelected: '',
      requesterIdSelected: '',
      bodyArrayEmpty: [],
      userProfile: [],

      acquittance: '',
      voucherTypeSelected: '',
      installmentsNumberSelected: '',
      minimumInstallmentValue: '',
      numberParcels: [],

      loadingCircle: false,
      showInstallmentsField: false,
      showMessageMinimumParcelValue: false,

      icons: {
        mdiCalendar,
      },
    }
  },

  watch: {
    voucherTypeSelected(newValue) {
      this.validateInstallments(newValue)
    },
  },

  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.leaderId = this.userProfile.id
  },

  methods: {
    async sendModalData() {
      const dateNow = new Date()

      const body = {
        description: this.inputDescription,
        value: Number(this.inputValue),
        status: 'SOLICITADO',

        for_users_leader: this.leaderId,
        status_leader: 'APROVADO',
        description_leader: 'APROVADO',
        date_leader: dateNow,

        sectors_id: this.sectorIdSelected,
        types_vouchers_id: this.voucherTypeSelected,

        number_parcel: !this.installmentsNumberSelected ? 1 : this.installmentsNumberSelected,
      }

      this.validateRequisitionBody(body)

      if (this.bodyArrayEmpty.length === 0) {
        this.loadingCircle = true

        await axiosIns
          .post('/api/v1/integration/vale/store', body)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
              showConfirmButton: false,
              timer: 3000,
            })

            this.updatedTable()
          })
          .catch(error => {
            if (error.response.status === 406) {
              const errorMensage = error.response.data.data.mensage
              this.showInstallmentError(errorMensage)

              return
            }

            this.$swal({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              showConfirmButton: false,
              text: error,
              timer: 3000,
            })
          })

        this.loadingCircle = false
        this.closeModal()

        return
      }

      this.bodyArrayEmpty = []

      this.$swal({
        icon: 'error',
        title: 'Campos vazios, preencha todos!',
        showConfirmButton: false,
        timer: 3000,
      })
    },

    validateRequisitionBody(body) {
      Object.keys(body).forEach(item => {
        if (!body[item]) {
          this.bodyArrayEmpty.push(item)
        }
      })
    },

    validateInstallments(id) {
      this.listOfInstallments.find(key => {
        if (key?.id === id) {
          if (key.number_parcel > 1) {
            this.showInstallmentsField = true
            this.numberParcels = this.range(1, key.number_parcel)
            this.numberParcels.shift()

            if (key.minimum_value_parcel > 0) {
              this.minimumInstallmentValue = `Valor mínimo da parcela é de ${key.minimum_value_parcel} reais`
              this.showMessageMinimumParcelValue = true

              return
            }

            this.showMessageMinimumParcelValue = false

            return
          }

          this.showInstallmentsField = false
        }
      })
    },

    /**
     * Cria um array de números inteiros em uma sequência aritmética.
     *
     * @param {number} start - O número inicial da sequência.
     * @param {number} end - O número final da sequência (não incluído no array resultante).
     * @returns {Array.<number>} Um array de números inteiros em uma sequência aritmética.
     *
     * @example
     * range(1, 5); // [1, 2, 3, 4]
     */
    range(start, end) {
      return Array.apply(1, Array(end)).map((_element, index) => index + start)
    },

    showInstallmentError(errorMessage) {
      this.$swal({
        icon: 'warning',
        title: 'Valor de parcela abaixo do permitido!',
        text: `${errorMessage}`,
        showConfirmButton: false,
        timer: 5000,
      })
    },

    closeModal() {
      this.$emit('close')
      this.inputDescription = ''
      this.inputValue = ''
      this.sectorIdSelected = ''
      this.voucherTypeSelected = ''
    },
  },
}
</script>

<style lang="scss">
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
