<template>
  <v-card>
    <v-card-title class="justify-center mb-2"> Solicitação de vales - CNH </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6" class="pb-0">
          <v-text-field
            v-model="inputValue"
            label="Valor"
            type="number"
            prefix="$"
            :rules="[rules.required]"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="userProfile.name"
            label="Líder"
            :rules="[rules.required]"
            outlined
            readonly
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="6" class="pb-0">
          <v-autocomplete
            v-model="sectorIdSelected"
            :items="sectorsList"
            item-text="name"
            item-value="id"
            label="Setor"
            :rules="[rules.required]"
            clearable
            outlined
            dense
          >
          </v-autocomplete>

          <v-autocomplete
            v-model="voucherTypeSelected"
            :items="voucherTypesList"
            item-text="description"
            item-value="id"
            label="Tipo de vale"
            :rules="[rules.required]"
            :loading="loadingVoucherType"
            clearable
            outlined
            dense
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" class="pt-0 pb-0">
          <v-autocomplete
            v-model="requesterIdSelected"
            :items="employeesList"
            item-text="name"
            item-value="id"
            label="Solicitante"
            :loading="loadingAutoComplete"
            clearable
            outlined
            dense
            @click:clear="showAcquittance = false"
          >
          </v-autocomplete>
        </v-col>

        <small v-show="showAcquittance" :class="colorAcquittance">
          {{ acquittanceMessage }}
        </small>
      </v-row>

      <vue-editor v-model="inputDescription" class="mt-4"></vue-editor>

      <div class="d-flex justify-end mt-5">
        <v-btn color="error" @click="closeModal"> Cancelar </v-btn>

        <v-btn color="info" class="ml-5" @click="sendModalData">
          <span v-if="!loadingCircle">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiCalendar } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters],

  props: {
    sectorsList: {
      type: [Array, Object],
      required: true,
    },

    employeesList: {
      type: [Array, Object, String],
      required: true,
    },
  },

  data() {
    return {
      inputValue: '',
      inputDescription: '',
      employeeSelected: '',
      leaderIdSelected: '',
      sectorIdSelected: '',
      requesterIdSelected: '',
      voucherTypeSelected: '',
      directorId: '',
      bodyArrayEmpty: [],
      userProfile: [],
      voucherTypesList: [],

      acquittanceMessage: '',
      acquittanceValue: '',
      colorAcquittance: '',
      referenceValue: '',

      loadingCircle: false,
      showAcquittance: false,
      loadingAutoComplete: false,
      loadingVoucherType: false,

      icons: {
        mdiCalendar,
      },
    }
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.dateInput)
    },
  },

  watch: {
    requesterIdSelected() {
      this.verifyAcquittance()
    },

    immediate: true,
  },

  async created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.leaderId = this.userProfile.id

    await this.getVoucherTypes()
    this.findDirector()
  },

  methods: {
    async getVoucherTypes() {
      this.loadingVoucherType = true

      await axiosIns.get('api/v1/integration/type_voucher/index_cnh').then(response => {
        this.voucherTypesList = response.data.data
        this.loadingVoucherType = false
      })
    },

    findDirector() {
      const director = this.employeesList.find(employee => employee?.profile?.profile === 'Diretor')

      this.directorId = director.id
    },

    async verifyAcquittance() {
      this.employeesList.map(key => {
        if (key.id === this.requesterIdSelected) {
          this.getAcquittance(key.id)
        }
      })
    },

    async getAcquittance(id) {
      this.loadingAutoComplete = true
      const body = {
        users_id: id,
      }

      axiosIns.post('api/v1/integration/contract/second_parcel_default_vendor', body).then(response => {
        this.acquittanceValue = response.data.data.acquittance2
        this.referenceValue = response.data.data.reference

        if (!this.acquittanceValue) {
          this.acquittanceMessage = ''

          this.loadingAutoComplete = false

          return
        }

        this.showAcquittance = true

        if (this.verifyAcquittanceValue()) {
          this.acquittanceMessage = `Elegível para solicitação. Atual ${this.acquittanceValue}. Referência ${this.referenceValue}.`
          this.loadingAutoComplete = false
          this.colorAcquittance = 'small-green'

          return
        }

        this.colorAcquittance = 'small-yellow'
        this.acquittanceMessage = `Adiplencia está abaixo de 75, atual ${this.acquittanceValue}. Referência ${this.referenceValue}.`

        this.loadingAutoComplete = false
      })
    },

    async sendModalData() {
      const dateNow = new Date()

      const body = {
        users_id: this.requesterIdSelected,
        description: this.inputDescription,
        value: Number(this.inputValue),
        status: 'SOLICITADO',

        for_users_leader: this.leaderId,
        status_leader: 'APROVADO',
        description_leader: this.inputDescription,
        date_leader: dateNow,

        for_users_director: this.directorId,
        description_director: 'APROVADO',
        status_director: 'APROVADO',
        date_director: dateNow,

        sectors_id: this.sectorIdSelected,
        types_vouchers_id: this.voucherTypeSelected,
        number_parcel: 1,
      }

      this.validateRequisitionBody(body)

      if (!this.verifyRequesterIsSelected()) {
        this.$swal({
          icon: 'error',
          title: 'Campo solicitante vazio!',
          text: 'Selecione o solicitante',
          showConfirmButton: false,
          timer: 4000,
        })

        return
      }

      if (!this.verifyAcquittanceValue()) {
        this.$swal({
          icon: 'error',
          title: 'Adiplencia está abaixo de 75',
          text: `Atual ${this.acquittanceValue}. Referência ${this.referenceValue}.`,
          showConfirmButton: false,
          timer: 4000,
        })

        return
      }

      if (this.bodyArrayEmpty.length === 0) {
        this.loadingCircle = true

        await axiosIns
          .post('/api/v1/integration/vale/store', body)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
              showConfirmButton: false,
              timer: 3000,
            })

            this.updatedTable()
          })
          .catch(error => {
            this.$swal({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              showConfirmButton: false,
              text: error,
              timer: 3000,
            })
          })

        this.loadingCircle = false
        this.closeModal()

        return
      }

      this.bodyArrayEmpty = []

      this.$swal({
        icon: 'error',
        title: 'Campos vazios, preencha todos!',
        showConfirmButton: false,
        timer: 3000,
      })
    },

    validateRequisitionBody(body) {
      Object.keys(body).forEach(item => {
        if (!body[item]) {
          this.bodyArrayEmpty.push(item)
        }
      })
    },

    verifyAcquittanceValue() {
      if (this.voucherTypeSelected === '43cd672c-17d3-4833-9108-011244c67684') {
        if (this.acquittanceValue >= 75) {
          return true
        }

        return false
      }

      if (this.voucherTypeSelected === '1473b619-cfb4-4abd-bc02-3c916f4788e4') {
        this.showAcquittance = false

        return true
      }

      return false
    },

    verifyRequesterIsSelected() {
      if (this.requesterIdSelected) {
        return true
      }

      return false
    },

    verifyVoucherTypeSelected() {
      if (this.voucherTypeSelected === '43cd672c-17d3-4833-9108-011244c67684') {
        if (this.acquittanceValue >= 75) {
          return true
        }

        return false
      }

      return false
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    formatDateUSA(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },

    closeModal() {
      this.$emit('close')
      this.inputDescription = ''
      this.inputValue = ''
      this.sectorIdSelected = ''
      this.voucherTypeSelected = ''
      this.dateInput = ''
    },
  },
}
</script>

<style lang="scss">
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.small-green {
  color: #00ff1a;
  font-size: larger;
  margin: 5px 10px 10px 15px;
}

.small-yellow {
  color: hsl(60, 100%, 50%);
  font-size: larger;
  margin: 5px 10px 10px 15px;
}
</style>
