<template>
  <div>
    <v-card class="mb-6">
      <v-card-text class="d-flex">
        <div class="container-content">
          <div class="container-ball-warning"></div>
          <span class="ml-2">Solicitado</span>
        </div>
        <div class="container-content">
          <div class="container-ball-info"></div>
          <span class="ml-2">Aprovado Líder</span>
        </div>
        <div class="container-content">
          <div class="container-ball-purple"></div>
          <span class="ml-2">Aprovado Diretor</span>
        </div>
        <div class="container-content">
          <div class="container-ball-success"></div>
          <span class="ml-2">Pago</span>
        </div>
        <div class="container-content">
          <div class="container-ball-error"></div>
          <span class="ml-2">Reprovado</span>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="d-flex justify-content-between">
        <span> Listagem de vales </span>
        <v-spacer></v-spacer>
        <v-btn
          v-show="verifyUserIsLeaderCNHOrDirector"
          color="warning"
          class="mr-8"
          outlined
          data-test="button-new-cnh"
          @click="openModalLeaderCNH"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          CNH
        </v-btn>

        <v-btn
          color="info"
          data-test="button-new"
          outlined
          @click="openModal"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="itemsTable"
        :loading="loading"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template #[`item.value`]="item">
          {{ Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
        </template>

        <template #[`item.status`]="{ item }">
          <v-alert
            class="badge"
            :color="
              item.status_leader === 'APROVADO' && !item.date_director
                ? 'info'
                : item.status_leader === 'REPROVADO'
                  ? 'error'
                  : item.status_director === 'APROVADO' && !item.status_financial
                    ? 'purple'
                    : item.status_financial === 'PAGO'
                      ? 'success'
                      : item.status_director === 'REPROVADO'
                        ? 'error'
                        : 'warning'
            "
            text
          >
            {{
              item.date_leader && !item.date_director
                ? `${item.status_leader} LÍDER`
                : item.date_director && item.status_financial
                  ? item.status_financial
                  : item.date_director
                    ? `${item.status_director} DIRETOR`
                    : 'SOLICITADO'
            }}
          </v-alert>
        </template>

        <template #[`item.description`]="{ item }">
          <div>
            {{ removeHtmlInString(item.description) }}
          </div>
        </template>
        <template #[`item.date_user`]="{ item }">
          {{ dateFormat(item.date_user) }}
        </template>

        <template #[`item.details`]="{ item }">
          <v-icon
            medium
            class="me-2"
            data-test="details-button"
            @click="openModalDescription(item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </template>

        <template #[`item.signature`]="{ item }">
          <v-icon
            medium
            class="me-2"
            color="warning"
            data-test="signature-button"
            @click="openSignature(item)"
          >
            {{ icons.mdiDraw }}
          </v-icon>
        </template>
      </v-data-table>

      <v-dialog
        v-if="hierarchicalProfile === 'nivel_1'"
        v-model="showModalHome"
        width="700px"
        persistent
      >
        <ModalHome
          :sectors-list="sectorsList"
          :employees-list="employeesList"
          :voucher-types-list="voucherTypesList"
          :list-of-installments="listOfInstallments"
          @updatedTable="updatedTable"
          @close="showModalHome = false"
        ></ModalHome>
      </v-dialog>

      <v-dialog
        v-else
        v-model="showModalLeader"
        width="700px"
        persistent
      >
        <ModalLeader
          :sectors-list="sectorsList"
          :employees-list="employeesList"
          :voucher-types-list="voucherTypesList"
          :list-of-installments="listOfInstallments"
          @updatedTable="updatedTable"
          @close="showModalLeader = false"
        ></ModalLeader>
      </v-dialog>

      <v-dialog
        v-model="showModalLeaderCNH"
        width="700px"
        persistent
      >
        <ModalLeaderCNH
          :sectors-list="sectorsList"
          :employees-list="employeesList"
          :voucher-types-list="voucherTypesList"
          :list-of-installments="listOfInstallments"
          @updatedTable="updatedTable"
          @close="showModalLeaderCNH = false"
        ></ModalLeaderCNH>
      </v-dialog>

      <v-dialog
        v-model="showModalDescription"
        width="700px"
        persistent
      >
        <HomeDetails
          :key="dataDetails.id"
          :data="dataDetails"
          :voucher-types-list="voucherTypesList"
          :sectors-list="sectorsList"
          @updatedTable="updatedTable"
          @close="closeModal"
        ></HomeDetails>
      </v-dialog>

      <v-dialog
        v-model="showSignature"
        width="700px"
        persistent
      >
        <Signature
          :key="dataDetails.id"
          :data="dataDetails"
          @updatedTable="updatedTable"
          @close="showSignature = false"
        ></Signature>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDraw, mdiEye, mdiPlaylistPlus } from '@mdi/js'
import LocalStorageSlim from 'localstorage-slim'
import HomeDetails from './HomeDetails.vue'
import ModalHome from './ModalHome.vue'
import ModalLeader from './ModalLeader.vue'
import ModalLeaderCNH from './ModalLeaderCNH.vue'
import Signature from './Signature.vue'

export default {
  components: {
    ModalHome,
    Signature,
    ModalLeader,
    HomeDetails,
    ModalLeaderCNH,
  },

  mixins: [formatters, messages],

  data() {
    return {
      showModalHome: false,
      showSignature: false,
      showModalLeader: false,
      showModalDescription: false,
      showModalLeaderCNH: false,

      lastStatus: '',
      dataDetails: '',
      loadingCircleChat: '',
      hierarchicalProfile: '',

      userProfile: [{}],
      sectorsList: [],
      voucherTypesList: [],
      employeesList: [],
      listOfInstallments: [],

      headers: [
        {
          text: 'TIPO',
          value: 'type_voucher.description',
          sortable: false,
          align: '',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'VALOR',
          value: 'value',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DA SOLICITAÇÃO',
          value: 'date_user',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DETALHES',
          value: 'details',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      loading: false,

      icons: {
        mdiPlaylistPlus,
        mdiDraw,
        mdiEye,
      },
    }
  },

  computed: {
    verifyUserIsLeaderCNHOrDirector() {
      const userIsSalesManager = this.userProfile.occupation_sector?.occupation.name === 'SUPERVISOR DE VENDAS'

      if (userIsSalesManager) {
        return true
      }

      return false
    },
  },

  created() {
    this.userProfile = LocalStorageSlim.get('userProfile', { decrypt: true })
    this.hierarchicalProfile = this.userProfile.hierarchical_profile
    this.employeesList = LocalStorageSlim.get('listEmployees', { decrypt: true })

    this.addSignatureFieldInHeader()

    this.getItemsTable()
    this.getSectorsList()
    this.getVoucherTypes()
  },

  methods: {
    async getItemsTable() {
      this.loading = true
      await axiosIns
        .get('/api/v1/integration/vale/index')
        .then(response => {
          this.itemsTable = response.data.data
          this.loading = false
        })
        .catch()
    },

    async getSectorsList() {
      await axiosIns
        .get('api/v1/records/sector/index')
        .then(response => {
          this.sectorsList = response.data.data
        })
        .catch()
    },

    async getVoucherTypes() {
      await axiosIns.get('api/v1/integration/type_voucher/index').then(response => {
        this.voucherTypesList = response.data.data
        response.data.data.map(key => {
          this.listOfInstallments.push({
            id: key?.id,
            number_parcel: key?.number_parcel,
            minimum_value_parcel: key?.minimum_value_parcel,
            event: key.event,
          })
        })
      })
    },

    addSignatureFieldInHeader() {
      if (this.isFinanceDepartmentMember()) {
        this.headers.push({
          text: 'ASSINATURA',
          value: 'signature',
          sortable: false,
          align: 'center',
        })
      }
    },

    isFinanceDepartmentMember() {
      const profile = this.userProfile?.profile?.profile

      if (profile === 'Financeiro') {
        return true
      }

      return false
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal() {
      this.showModalHome = true
      this.showModalLeader = true
    },

    openModalLeaderCNH() {
      this.showModalLeaderCNH = true
    },

    openModalDescription(item) {
      this.loadingCircleChat = item.id
      this.showModalDescription = true
      this.dataDetails = item
    },

    openSignature(item) {
      const isSignaturePresent = this.checkSignatureIsPresent(item.customer_subscription)

      if (isSignaturePresent) {
        this.dataDetails = item
        this.showSignature = true

        return
      }

      this.showMessage({
        icon: 'warning',
        title: 'Não assinado',
        text: 'Aguarde até o processo de assinatura ser feita pelo usuário',
      })
    },

    checkSignatureIsPresent(signature) {
      if (!signature) {
        return false
      }

      return true
    },

    closeModal() {
      this.showModalDescription = false
      this.dataDetails = ''
      this.loadingCircleChat = ''
    },
  },
}
</script>

<style scoped>
.badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-content {
  display: flex;
  align-items: center;
  width: 200px;
}
.container-ball-warning {
  background: #ffb400;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-info {
  background: #16b1ff;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-purple {
  background: #9155fd;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-success {
  background: #56ca00;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-error {
  background: #ff4c51;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}
</style>
