<template>
  <div>
    <v-card :key="data.id">
      <v-card-title class="d-flex justify-center">
        Assinatura do solicitante
      </v-card-title>

      <v-card-text>
        <img
          :src="signatureImage"
          alt="signature-image"
          class="image"
        />
        <div class="d-flex justify-end mt-5">
          <v-btn
            color="info"
            data-test="send-button"
            @click="resendSignatureLink"
          >
            <span v-if="!loadingCircle">Reenviar link</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
          <v-btn
            color="error"
            class="ml-5"
            data-test="close-button"
            @click="closeModal"
          >
            Fechar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LocalStorageSlim from 'localstorage-slim'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      loadingCircle: false,
      signatureImage: '',
      hierarchicalProfile: '',

      userProfile: [],
    }
  },

  created() {
    this.userProfile = LocalStorageSlim.get('userProfile', { decrypt: true })
    this.hierarchicalProfile = this.userProfile.hierarchical_profile
    this.signatureImage = this.data.customer_subscription
  },

  methods: {
    async resendSignatureLink() {
      this.loadingCircle = true

      await axiosIns
        .put(`/api/v1/integration/vale/resend_voucher_link/${this.data.id}`)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta foi enviada com sucesso!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })

      this.loadingCircle = false
      this.closeModal()
    },
  },
}
</script>
<style scoped>
.image {
  width: 100%;
  height: 11.6667rem;
  border-radius: 0.2778rem;
}
</style>
le
